.box-shadow-address {
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.box-address {
    border-radius: 5px;
    border: 1px dotted;
    display: flex;
    height: 206px;
    align-items: center;
    justify-content: center;
}

.center-item {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}