.total-form-payment, .total-payment{
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #CCD1D1;
}

@media (max-width: 899.5px){
    .logo_payments{
        width: 100px !important;
    }
}

@media (max-width: 767px){
    .mp_img{
        width: 120%;
    }
}

@media (max-width: 320px){
    .mp_img{
        width: 100%;
    }
}