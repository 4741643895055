.scroll_input {
  scroll-margin-top: 90px;
}

.box_branches {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 8px;
}

.flex_branches {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.img_branches {
  width: 150px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
}

.info_flex_branches {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 575px) {
  .flex_branches {
    display: block;
    text-align: center;
  }

  .img_branches {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .info_flex_branches {
    justify-content: center;
  }

}