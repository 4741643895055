.form-register {
    width: 50%;
    margin: auto;
}

@media (max-width: 991.5px) {
    .form-register {
        width: 80% !important;
    }
}

@media (max-width: 425.9px) {
    .form-register {
        width: 100% !important;
    }
}

/* botón registro */
.btn-register {
    width: 100%;
    background-color: #2699FB;
    color: #fff;
    height: 50px;
    transition: all .4s ease-in-out;
}

.btn-register:hover {
    background-color: #2285db;
    color: #fff;
}

/*buttons social register*/
.buttons-desktop-register button {
    width: 350px;
    height: 50px;
    border-radius: 10px;
}

@media(max-width: 425.9px) {
    .buttons-desktop-register {
        display: none;
    }
}

/* buttons social register mobile */
.btn-circle-register.btn-xl {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
}


.buttons-mobile-register {
    display: none;
}

@media(max-width: 425.9px) {
    .buttons-mobile-register {
        display: block;
    }

    .buttons-mobile-register {
        width: 70%;
    }
}

@media (max-width: 350px) {
    .buttons-mobile-register {
        width: 80% !important;
    }
}

@media (max-width: 310px) {
    .buttons-mobile-register {
        width: 100% !important;
    }
} 

/* Botón segundo paso */
.btn__register_step_2{
    width: 100%;
    background-color: #2699FB;
    color: #fff;
    height: 50px;
    transition: all .4s ease-in-out;
}

.btn__register_step_2:hover{
    background-color: #2285db;
    color: #fff;
}

/* Step 2 */
.step-2__button__next{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

@media (max-width: 991.5px){
    .step-2__button__next{
        width: 70%;
    }
}

@media (max-width: 425.9px){

    .step-2__button__next{
        width: 100% !important;
        position: absolute;
        bottom: 0
    }

    .btn__register_step_2{
        border-radius: 0 !important;
    }
}

/* Step 3 */
.desktop-datepicker-register{
    display: block;
}

.mobile-datepicker-register{
    display: none;
}

@media (max-width: 425.9px){
    .desktop-datepicker-register{
        display: none;
    }

    .mobile-datepicker-register{
        display: block !important;
    }
}