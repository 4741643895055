/* Carrito */
.cart-box-shadow {
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
}

.cart-box-shadow:not(:last-child){
    margin-bottom: 30px;
}

.total-cart-box-shadow{
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
}

@media (max-width: 991.5px ){
    .cart-img{
        width: 65% !important;
    }
}

@media (max-width: 425.9px){
    .cart-img{
        width: 85% !important;
    }
}

@media (max-width: 630px){
    .abandoned_cart_img{
        width: 55%;
    }
}

@media (max-width: 320.5px){
    .abandoned_cart_img{
        width: 65%;
    }
}