.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    padding-bottom: 45px;
}

.box_promotion{
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border-radius: 7px;
}