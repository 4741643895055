.new-address-form{
    border-radius: 3px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    width: 60%;
    margin: auto;
}

@media (max-width: 991.5px){
    .new-address-form{
        width: 100% !important;
    }
}

.btn-new-address{
    width: 300px;
}

@media (max-width: 767.5px){
    .btn-new-address{
        width: auto !important;
    }
}