/*****************SIDEBAR Admin**************/

.sidebarAdmin a {
  color: inherit;
  text-decoration: none;
}

.sidebarAdmin ul li a {
  padding: 10px 10px 10px 20px;
  font-size: 1.1rem;
  display: block;
  transition: all 0.2s ease-in-out;
}

.sidebarAdmin ul li a:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.sidebarAdmin a[data-toggle="collapse"] {
  position: relative;
}

.sidebarAdmin .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.sidebarAdmin ul li a i {
  width: 35px;
  font-size: auto;
  margin: auto !important;
}

/**************************************/

/*Remove button style*/
.button-admin-active-sidebar {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Sidebar User */
.bg-wrap {
  width: 100%;
  position: relative;
  z-index: 0;
  text-align: center;
  margin: 0;
  padding: 20px;
}

.img-admin-sidebar-header {
  background-image: url("../../../assets/images/encabezado.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/encabezado.jpg");
  background-image: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/encabezado.jpg");
  background-image: -o-linear-gradient(top,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/encabezado.jpg");
  background-image: -ms-linear-gradient(top,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/encabezado.jpg");
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.4)),
      to(rgba(0, 0, 0, 0.8))),
    url("../../../assets/images/encabezado.jpg");
  background-image: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/encabezado.jpg");
}

.bg-wrap .user-logo .img-user-logo {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.img-user-logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}