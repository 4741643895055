.box-nuestro-equipo {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    width: 60%;
    margin: auto;
}

@media (max-width: 991.5px){
    .box-nuestro-equipo{
        width: 100% !important;
    }
}