.banner_medical{
    margin-top: 45px;
}

@media (max-width: 950px){
    .banner_medical{
        margin-top: 0;
    }
}

.medical_directory_mobile {
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

.medical_directory_avatar_mobile {
    width: 90px !important;
    height: 90px !important;
}

.medical_directory_inputs {
    width: 280px;
}

@media (max-width: 375px) {
    .btn_directory_more {
        margin-top: 10px !important;
    }

    .medical_directory_mobile {
        display: block;
        text-align: center;
    }

    .medical_directory_avatar_mobile {
        margin: 0 auto 10px auto !important;
    }
}

@media (max-width: 300px) {
    .medical_directory_inputs {
        width: 250px;
    }
}

/* Detalle del contacto médico */
.medical_directory_detail_flex {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.medical_info {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
}

.btn_back_desktop {
    display: flex;
    justify-content: flex-end;
}

.btn_back_mobile {
    display: none;
}

.btn_back_mobile_top {
    display: none;
}

@media (max-width: 500px) {
    .medical_directory_detail_flex {
        display: block;
        text-align: center;
    }

    .medical_avatar {
        margin-bottom: 25px !important;
    }

    .medical_place_icon {
        display: none;
    }

    .medical_info {
        display: block;
    }

    .medical_info img {
        width: 40px;
        margin-bottom: 8px;
    }

    .btn_back_desktop {
        display: none !important;
    }

    .btn_back_mobile {
        display: flex;
        justify-content: flex-end;
    }

    .medical_directory_specialties,
    .medical_directory_diseases_treated,
    .medical_directory_education {
        padding: 0;
    }

    .medical_directory_diseases_treated li {
        margin: 5px 0;
        font-size: 14px;
    }

    .medical_directory_education li {
        font-size: 14px;
    }

    .btn_back_mobile_top {
        display: block;
        margin-bottom: 15px;
    }
}

.medical_directory_specialties li,
.medical_directory_diseases_treated li,
.medical_directory_education li {
    list-style-type: none;
}

.medical_directory_education li {
    margin: 15px 0px;
}

.medical_directory_specialties li:before,
.medical_directory_diseases_treated li:before,
.medical_directory_education li:before {
    content: "-";
    margin-right: 5px;
}

.bg_medical {
    background-image: url('/src/assets/resources/fondo.png');
    background-repeat: repeat-y;
    background-size: cover;
}

.bg_medical_detail{
    background-image: url('/src/assets/resources/fondo.png');
    background-repeat: repeat-y;
    background-size: cover;
}