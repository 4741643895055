.grid-container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: 1fr;
}

.image {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e1e1;
}

.img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img img {
    max-width: 100%;
}

.uploader {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #e1e1e1;
    max-width: 100%;
}

@media (max-width: 991.5px){
    .grid-container{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 660px){
    .grid-container{
        grid-template-columns: repeat(2, 1fr);
    }
}
