.banners-desktop{
    display: block;
    margin-top: 30px;
}

.banners-mobile{
    display: none;
}

.banners-tablet{
    display: none;
}

@media (max-width: 991.5px){
    .banners-desktop{
        display: none !important;
    }

    .banners-tablet{
        display: block !important;
    }
}

@media (max-width: 500px){
    .banners-tablet{
        display: none !important;
    }

    .banners-mobile{
        display: block !important;
    }
}

/* Nuevo index */
.banners-desktop .swiper-button-next,
.banners-mobile .swiper-button-next,
.banners-tablet .swiper-button-next {
    color: #fff;
}

.banners-desktop .swiper-button-prev,
.banners-mobile .swiper-button-prev,
.banners-tablet .swiper-button-prev {
    color: #fff;
}

.video-wrapper {
    padding-bottom: 56.25%;
    position: relative;
}

.video-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%;
}

.card_services {
    box-shadow: 0px 0px 9px 5px rgba(164, 210, 225, 1);
    border-radius: 30px;
}

@media (max-width: 500px) {

    .card_services {
        height: auto !important;
    }

    .card_services_header {
        text-align: center;
    }
}

.products_right_side {
    background-image: url('../../assets/resources/products_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.products_wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;

}

.products_left_side {
    width: 40%;
}

.products_right_side {
    width: 60%;
}

@media (max-width: 1100px) {
    .products_wrap {
        flex-wrap: wrap !important;
        flex-direction: column-reverse;
    }

    .products_left_side {
        width: 100%;
        display: none;
    }

    .products_right_side {
        width: 100%;
    }

}

@media (max-width: 767.5px) {
    .products_right_side {
        text-align: center;
    }
}

@media (max-width: 575.5px) {
    .product_img_one img {
        width: 30% !important;
    }

    .product_img_two img {
        width: 50% !important;
    }
}

@media (max-width: 500px) {
    .products_left_side {
        display: block !important;
    }
}

@media (max-width: 375.5px) {
    .product_img_one img {
        width: 45% !important;
    }

    .product_img_two img {
        width: 60% !important;
    }
}

.bg_research {
    background-image: url('../../assets/images/27.png');
}

.bg_bowl {
    background-image: url('../../assets/images/close-up-bowl.png');
}

.bg_research,
.bg_bowl {
    width: 100%;
    height: 310px;
    background-size: cover;
    background-position: center;
    border-radius: 60px;
    margin-bottom: 15px;
}

.coupon_card {
    border-radius: 10px !important;
    box-shadow: 8px 9px 21px -5px rgba(86, 197, 150, 0.75) !important;
    width: 480px;
}

.coupon_flex {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.coupon_left {
    width: 40%;
    text-align: left;
}

.coupon_rigth {
    width: 60%;
}

@media (max-width: 535px) {

    .coupon_card {
        width: 100%;
    }

    .coupon_flex {
        display: flex;
        flex-wrap: wrap !important;
    }

    .coupon_left,
    .coupon_rigth {
        width: 100%;
        text-align: center;
    }

    .coupon_left img {
        width: 30% !important;
    }

}

@media (max-width: 375.5px) {
    .coupon_left img {
        width: 40% !important;
    }
}

.scroll {
    width: 100%;
    height: 330px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}

.scroll::-webkit-scrollbar {
    width: 14px;
}

.scroll::-webkit-scrollbar-track {
    background-color: rgba(86, 197, 150, 1);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #FFFFFF;
}

.dis_sec,
.med_sec {
    display: flex;
    justify-content: flex-start;
    background-color: #D7EEF9;
    background-image: url('../../assets/resources/products_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

}

.med_sec {
    text-align: right;
}

.dis_left,
.dis_rigth,
.med_left,
.med_rigth {
    width: 50%;
}

@media (max-width: 940px) {

    .dis_sec,
    .med_sec {
        flex-wrap: wrap !important;
        text-align: center;
    }

    .med_sec {
        flex-direction: column-reverse;
    }

    .dis_left,
    .dis_rigth,
    .med_left,
    .med_rigth {
        width: 100%;
    }
}

.index_select .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #56C596 !important;
    border-radius: 18px !important;
    color: red !important;
    box-shadow: rgba(50, 157, 156, 0.2) 0px 2px 8px 0px;
}

.index_select .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #56C596 !important;
}

.index_select .MuiInputLabel-root {
    color: #56C596 !important;
}

.bg_index {
    background-image: url('/src/assets/resources/fondo.png');
    background-repeat: repeat-y;
    background-size: contain;
}