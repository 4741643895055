footer {
  background: #00A7E2;
}

.links_footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem 4rem;
}

.copyright {
  text-align: center;
  color: #fff;
}

@media (max-width: 500px) {
  .links_footer {
    display: none !important;
  }
}