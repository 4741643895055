.mi-cuenta-cards{
    border: 1px solid rgb(231, 231, 231);
    border-radius: 6px;
    transition: all .2s ease-in-out;
    height: 175px;
}

.mi-cuenta-cards:hover{
    background-color: #ebeef0;
}

.datos-personales{
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(231, 231, 231);
    border-radius: 5px;
}
