.review_carousel {
    display: none;
    margin-top: 15px;
}

@media (max-width: 599.5px) {
    #review_image {
        display: none;
    }

    .review_carousel {
        display: block;
    }
}