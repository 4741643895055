@media (max-width: 425.9px) {
    .btn-drawer-shop-style {
        display: none !important;
    }
}

.drawer-cart {
    height: calc(100vh - 220px);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    position: relative;
}