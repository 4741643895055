.login-checkout-account, .login-checkout-invitado{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    border: 1px solid #CCD1D1;
}

@media (max-width: 767.5px){
    .login-checkout-invitado{
        height: auto !important;
    }
}