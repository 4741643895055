/* info personal */
.personal-info-form {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
}

.total-form-fixed {
    position: sticky;
    top: 140px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
}
