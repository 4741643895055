/*button desktop*/
.buttons-desktop-login button {
    width: 350px;
    height: 50px;
    border-radius: 10px;
}

@media(max-width: 425.9px) {
    .buttons-desktop-login {
        display: none;
    }
}

/*Button mobile*/

.btn-circle-login.btn-xl {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
}

.buttons-mobile-login {
    display: none;
}

@media(max-width: 425.9px) {
    .buttons-mobile-login {
        display: block;
    }

    .buttons-mobile-login {
        width: 70%;
    }
}

@media (max-width: 350px) {
    .buttons-mobile-login {
        width: 80% !important;
    }
}

@media (max-width: 310px) {
    .buttons-mobile-login {
        width: 100% !important;
    }
}

/* Image background */
.login-bg-img {
    background-image: url('../../../assets/images/full-sucursal.png');
    padding-top: 150px;
    padding-bottom: 30px;
}


@media (max-width: 950px) {
    .login-bg-img {
        padding-top: 40px;
    }
}

@media (max-width: 425.9px) {
    .login-bg-img {
        background-image: none;
    }
}

/* Logo view Login */
.login-logo-mobile {
    display: none;
}

.login-logo-desktop {
    margin: auto;
}

.login-bg-img img {
    -khtml-user-drag: none;
    -webkit-user-drag: none;
    -o-user-drag: none;
    -moz-user-drag: none;
}

@media (max-width: 425.9px) {
    .login-logo-mobile {
        display: block !important;
        margin: auto;
    }

    .login-logo-desktop {
        display: none !important;
    }
}

@media (max-width: 991.5px) {
    .login-logo-desktop {
        width: 50% !important;
    }
}

@media (max-width: 767.5px) {
    .login-logo-desktop {
        width: 70% !important;
    }
}

@media (max-width: 375.9px) {
    .login-logo-mobile {
        width: 80% !important;
    }
}

/* formulario */
.login-form {
    width: 40%;
    margin: auto;
}

@media (max-width: 991.5px) {
    .login-form {
        width: 60% !important;
    }
}

@media (max-width: 767.5px) {
    .login-form {
        width: 100% !important;
    }
}

.btn-login {
    width: 100%;
    background-color: #2699FB;
    color: #fff;
    height: 50px;
    transition: all .4s ease-in-out;
}

.btn-login:hover {
    background-color: #2285db;
    color: #fff;
}