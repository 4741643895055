.distribuidor-autorizado-banner img {
    pointer-events: none;
}

@media (max-width: 991.5px) {
    .distribuidor-autorizado-banner img {
        width: 65% !important;
    }
}

@media (max-width: 767.5px) {
    .distribuidor-autorizado-banner img {
        width: 100% !important;
    }
}