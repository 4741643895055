.ty-page{
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
    width: 55%;
}

@media (max-width: 991.5px){
    .ty-page{
        width: 80% !important;
    }
}

@media (max-width: 767.5px){
    .ty-page{
        width: 95% !important;
    }
}

@media (max-width: 425.9px){
    .ty-page{
        width: 100% !important;
    }
}