.list_margin li {
    margin: 10px 0;
    margin: 0;
    padding: 0;
}

.tab_table td,
.tab_table th {
    border: 3px solid #34A571;
}

.sticky_fixed {
    position: sticky;
    top: 150px;
}

.article_btn {
    background-color: #2978A0;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: auto;
    border: 1px solid #2978A0;
    border-radius: 5px 0 0 5px;
    transition: all .3s ease-in-out;
}

.article_btn:hover {
    background-color: #1781b6;
}

@media (max-width: 425.9px) {
    .article_btn {
        border-radius: 0 5px 5px 0 !important;
    }
}