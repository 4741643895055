.appbar {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(232, 232, 233, 0.7) 75%, rgba(232, 232, 233, 0.7) 100%) !important;
}

.appbar.active {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%) !important;
    transition: all 0.3s ease-in-out;
}

.navbar_mobile {
    display: none !important;
}

@media (max-width: 950px) {
    .navbar_desktop {
        display: none !important;
    }

    .navbar_mobile {
        display: block !important;
    }
}

.links__ .active {
    background-color: rgba(148, 204, 53, 0.5) !important;
    color: #000 !important
}

.links_mobile__ .active {
    color: #3DAAF7 !important;
}

.search_into_header {
    width: 100%;
    margin-right: 6px;
    overflow-x: hidden !important;
}