/**/
.impulse_grid_container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 5px;
    grid-auto-rows: 1fr;
    margin-top: 20px;
}

.impulse_image {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.impulse_img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.impulse_img img {
    max-width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #F2F3F4;
}


@media (max-width: 991.5px) {
    .impulse_grid_container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 660px) {
    .impulse_grid_container {
        grid-template-columns: repeat(2, 1fr);
    }
}