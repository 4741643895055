/*Ratings*/
.ratings-shop i {
    font-size: 20px;
}

.ratings-shop .far,
.ratings-shop .fa {
    color: #FFD700 !important
}

.cat-primary {
    background-color: #81D4FA;
}

.cat-secondary {
    background-color: #E1F5FE;
}

/*Productos*/
.shop-product {
    transition: all .5s ease-in-out;
}

.shop-product:hover {
    transform: scale(1.02);
}

/* Select para ordenar */
.order-by-shop {
    width: 200px;
}

@media (max-width: 425.9px) {
    .order-by-shop {
        width: 100% !important;
    }
}

/* Botón flotante para abvrir drawer del carrito */
.btn-drawer-cart {
    position: fixed;
    bottom: 30px;
    right: 20px;
}

/* Producto en promoción */
.product-in-promotion {
    position: relative;
    display: inline-block;
}

.badge-product {
    display: table;
    position: absolute;
    top: -5px;
    right: -10px;
    text-align: center;
    background-color: #00A7E2;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px dotted #ffff;
    outline: 3px solid #00A7E2;
}

.badge-product p {
    display: table-cell;
    vertical-align: middle;
    font-size: .857em;
    font-weight: bold;
}

.category_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid rgb(216, 216, 216);
}

@media (max-width: 991.5px) {
    .safe_purchase {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .safe_purchase {
        width: 100%;
    }
}

@media (max-width: 575.5px) {
    .shop-product {
        width: 100%;
    }
}

.product-in-promotion {
    position: relative;
    display: inline-block;
}

.banners-shop-desktop {
    display: block;
    margin-top: 48px;
}

.banners-shop-mobile {
    display: none;
}

.banners-shop-tablet {
    display: none;
}

.banners-shop-desktop .swiper-button-next,
.banners-shop-mobile .swiper-button-next,
.banners-shop-tablet .swiper-button-next {
    color: #fff;
}

.banners-shop-desktop .swiper-button-prev,
.banners-shop-mobile .swiper-button-prev,
.banners-shop-tablet .swiper-button-prev {
    color: #fff;
}

@media (max-width: 950px) {
    .banners-shop-desktop {
        display: none !important;
    }

    .banners-shop-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banners-shop-tablet {
        display: none !important;
    }

    .banners-shop-mobile {
        display: block !important;
    }
}

.shop_top-image {
    position: absolute;
    top: 0px;
    left: 0px;
}

.shop_centered_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box_shop {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
    border-radius: 10px;
}

.banner_img img {
    max-height: 570px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    object-position: center 82%;
}

.shop_bg {
    background-color: #F6F6F6;
}

.shop_prod {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #F8F9F9;
    border-radius: 8px;
}

@media (max-width: 991.5px) {
    .shop-product {
        height: 350px;
        width: 100%;
    }
}

@media (max-width: 880px) {
    .shop-product {
        height: 280px;
        width: 100%;
    }
}

@media (max-width: 750px) {
    .shop-product {
        height: auto;
        width: 100%;
    }
}