@media (max-width: 991.5px) {
    .plan-lealtad-video video {
        width: 100% !important;
    }
}

.border-nr {
    width: 430px;
    padding: 25px;
    border: 2px solid #8bc53f !important;
    border-radius: 8px;
}

@media (max-width: 520px){
    .border-nr{
        width: 100% !important;
    }
}

@media (max-width: 425.9px){
    .events img{
        width: 100% !important;
        height: auto !important;
    }
}

/* Membresias */
@media (max-width: 360px){
    .card_memberships{
        height: auto !important;
    }
}