.box-shadow-add-product-category {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

.box-shadow-add-product-flavor-capacity {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

.avatar_empty{
    background-color: transparent;
    color: #000;
}

.avatar_fill{
    text-transform: uppercase;
    width: 35px !important;
    height: 35px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    cursor: pointer;
    background-color: #00A7E2 !important;
}