.promotion_active_card .css-1blymkb-MuiTypography-root {
    font-size: 1.3rem !important;
}

.promotion_active_card {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%) !important;
    border-radius: 5px;
    width: 100%;
}

@media (max-width: 767.5px) {
    .promotion_active_card {
        height: auto !important;
    }
}