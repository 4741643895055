.video-responsive {
    overflow:hidden;
    padding-bottom:400px;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

@media (max-width: 425.9px){
    .video-responsive{
        overflow:hidden;
        padding-bottom: 300px !important;
    }
}

.faqs .sticky-top { 
    top: 1em; 
}

@media (max-width: 991.5px){
    .faqs .col-lg-8{
        margin-top: 30px;
    }
}

