@media (max-width: 425.9px){
    .logo-acc-create{
        width: 70% !important;
    }
}

@media (max-width: 767.9px){
    .message-thx-register{
        margin-top: 20px !important;
    }
}

@media (max-width: 425.9px){
    .title-thx{
        font-size: 28px;
    }
}

@media (max-width: 370px){
    .title-thx{
        font-size: 22px;
    }
}
