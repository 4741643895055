.map path {
    fill: #007bbf;
    stroke: #ffff;
    stroke-width: 1px;
}

.map path:hover {
    fill: #00527e;
}

.clientes-nuevos-card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.clientes-nuevos-card {
    height: 582px;
}

@media (max-width: 1199.5px) {

    .clientes-nuevos-card {
        height: auto;
    }
}