@media (max-width: 425.9px) {
    .estatus-pedido {
        margin-top: 20px;
    }
}

.info-producto-pedidos{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 586.5px) {
    .info-producto-pedidos {
        justify-content: center !important;
        text-align: center;
    }
}

@media (max-width:991.9px) {
    .buttons-pedidos {
        margin-top: 20px !important;
    }

    .details_btn_buy{
        margin-top: 15px !important;
    }
}

/* Box shadow */
.pedidos-component {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

.padding-for-products {
    padding: 0 8px 0 8px;
}

/*Estilos 2 o más productos avatar*/
.top-right {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #2699FB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff !important;
    text-decoration: none !important;
    cursor: pointer;
}
