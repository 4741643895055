.card__pedidos {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%) !important;
    border-radius: 5px;
    width: 100%;
}

@media (max-width: 767.5px) {
    .card__pedidos {
        height: auto !important;
    }
}

.register_content {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    flex-wrap: wrap;
}

@media (max-width: 505px) {
    .register_content {
        justify-content: flex-start;
    }
}

.register_melonn {
    width: 50px;
    height: 20px;
    border: 2px solid #7DCEA0;
    border-radius: 3px;
    background-color: #A9DFBF;
}

.no_register_melonn {
    width: 50px;
    height: 20px;
    border: 1px solid #696969;
    border-radius: 3px;
    background-color: #fff;
}

/* tbody > tr:hover > td {
    background-color: unset !important;
    color: unset !important;
} */