.list_billing_address {
    width: 100%;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border-radius: 6px;
}

.new-billing-address-form {
    border-radius: 3px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    width: 80%;
    margin: auto;
}

@media (max-width: 991.5px) {
    .new-billing-address-form {
        width: 100% !important;
    }
}