/*Nuestro equipo*/
#equipoFondo{
    background-color: #00A7E2;
    color: white;
    text-align: center;
}

.img_equipofondo{
    width: 55%;
}

@media (max-width: 991.5px){
    .img_equipofondo{
        width: 100% !important;
    }
}


/*Carousel de clientes*/
.carousel-cte .cte1 img{
    width: 55% !important;
    margin: auto;
}
.carousel-cte .cte2 img, .cte3 img{
    width: 50% !important;
    margin: auto;
}


@media (min-width: 991.5px){
    .menciones-text{
        width: 60%;
        margin: auto;
        text-align: center;
    }
}

.menciones-text{
    font-size: 18px;
}

@media (max-width: 767.5px){
    .about-cards{
        height: auto !important;
    }
}