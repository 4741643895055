.numero-rastreo, .direccion-envio-rastreo{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
}

.rastreo-box-shadow{
    width: 600px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

@media (max-width: 768px){
    .rastreo-box-shadow{
        width: 100% !important;
    }
}