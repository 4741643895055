.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.product_gallery {
  position: sticky;
  top: 130px;
}

@media (max-width: 991.5px) {
  .image-buy-tog {
    width: 230px !important;
    height: 230px !important;
  }
}

@media (max-width: 820.5px) {
  .image-buy-tog {
    width: 180px !important;
    height: 180px !important;
  }
}

@media (max-width: 650.5px) {
  .image-buy-tog {
    width: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 500px) {
  .icon-bf {
    font-size: 1rem !important;
  }
}

.product_grid_3 {
  border: 1px solid rgb(210, 210, 210);
  border-radius: 5px;
  box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
  padding: 15px;
}

.product_top-image {
  position: absolute;
  top: 0px;
  left: 25%;
}

.product_centered_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 425.9px) {
  .product_top-image img {
    width: 75px !important;
  }

  .no_promotion_text {
    font-size: 12px !important;
  }
}

#portal {
  position: absolute;
  z-index: 2;
}

@media (max-width: 950px) {
  #portal {
    top: 0 !important;
    left: 0 !important;
  }
}

@media (max-width: 525px) {
  .image_tog_prom {
    width: 100%;
  }
}

@media (max-width: 767px){
  .prom_cont{
    display: block !important;
  }
}

@media (max-width: 375px) {
  .promo_dialog {
    display: block !important;
  }
}

.check_svg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #56C596;
  stroke-miterlimit: 10;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #56C596;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@media (max-width: 500px) {
  .checkmark {
    width: 70px;
    height: 70px;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}