.resumen-checkout {
    border-radius: 3px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
    width: 600px;
}

@media (max-width: 768px){
    .resumen-checkout{
        width: 100% !important;
    }
}